<div class="blog-title blog-title-bg bg-2 bg-google-ads">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="blog-title-text">
                    <h2>Blog Details</h2>
                    <ul>
                        <li>
                            <a routerLink="/index">Home</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            <a routerLink="/google-ads">Google Ads</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <div class="row">
        <div class="col-lg-12">
            <div class="google-ads-content d-flex justify-content-between align-items-center flex-wrap">
                <div class="google-ads-content-item" *ngFor="let item of listFunction"
                    [ngClass]="{'active': item.active}" (click)="handleClickViewAction(item.id)">
                    <div class="item-background"></div>
                    <div class="item-content">{{item.title}}</div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="idActionTab == 1" @expandCollapse>
    <div class="container-fluid wrapper-quang-cao-google bg-google-ads-color">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <h2 class="line-before text-md-32 mb-20 text-white">Quảng cáo Google Ads</h2>
                    <div class="text-white text-justify text-quang-cao-google">
                        <p>Google đã trở thành công cụ tìm kiếm số 1 hiện nay trên thế giới. Theo thống kê tại Việt Nam,
                            90%
                            số người dùng sử dụng Google cho các nhu cầu tìm kiếm của mình.</p>
                        <p>Google Ads là kênh quảng cáo trả phí của Google, bạn có thể tạo chiến dịch theo nhiều hình
                            thức
                            khác nhau với mục đích cuối cùng là: <strong>TÌM KIẾM KHÁCH HÀNG TIỀM NĂNG và MANG VỀ LỢI
                                NHUẬN</strong>. Google Ads là hình thức quảng cáo giúp khách hàng tìm đến bạn
                            <strong>MỘT
                                CÁCH CHỦ ĐỘNG</strong>. Khi họ tìm kiếm những thông tin liên quan đến doanh nghiệp của
                            bạn
                            trên Google thì nội dung quảng cáo của bạn sẽ được xuất hiện ngay lập tức.
                        </p>
                        <a class="btn-lien-he-google  mb-3" routerLink="/lien-he">Liên hệ ngay</a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <img src="../../../../../assets/img/google-ads-la-gi-tranyipr.jpg" width="550px" height="320px" alt="" srcset=""
                        class="w-100">
                </div>
            </div>
        </div>
    </div>
    <div class="container wrapper-google-ads-help">
        <div class="row">
            <div class="col-lg-12">
                <h2 class="mb-5">Google Ads giúp gì cho doanh nghiệp của bạn?</h2>
            </div>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6">
                <img src="../../../../../assets/img/grow-up-1600227336.jpg" alt="" class="w-100 mb-3">
            </div>
            <div class="col-lg-6">
                <h3>Phát triển doanh nghiệp</h3>
                <div class="text-justify">
                    <p>Hiển thị quảng cáo cho khách hàng khi họ đang tìm kiếm các doanh nghiệp giống như doanh nghiệp
                        của
                        bạn trên Google tìm kiếm và Google Maps. Khách hàng sẽ cảm thấy an tâm hơn khi doanh nghiệp của
                        bạn
                        có đầy đủ các thông tin được xác thực tại Google. Bạn chỉ phải trả tiền khi quảng cáo được khách
                        hàng nhấp vào để đi tới trang web hoặc khi khách hàng gọi đến doanh nghiệp của bạn.</p>
                </div>
            </div>
        </div>
        <div class="row wrapper-google-ads-help-item">
            <div class="col-lg-12">
                <h3>Hiển thị khi mọi người tìm kiếm sản phẩm / dịch vụ mà bạn cung cấp</h3>
                <div class="text-justify">
                    <p>Google luôn là trang tìm kiếm hàng đầu mà mọi người sử dụng để kiếm những việc cần làm, những địa
                        điểm muốn đến và những thứ muốn mua. Quảng cáo của bạn có thể xuất hiện trên Google ngay khi có
                        ai
                        đó tìm kiếm các sản phẩm hoặc dịch vụ tương tự như sản phẩm và dịch vụ của bạn.</p>
                    <p>Cho dù mọi người sử dụng máy tính hay thiết bị di động, quảng cáo của google cũng sẽ hiển thị
                        ngay
                        khi mọi người tìm từ khóa và biến khách hàng trở thành khách hàng tiềm năng.</p>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid wrapper-google-ads-help-item-2">
        <div class="container">
            <div class="row">
                <div class="col-12 text-center text-white">
                    <h2>NEWTRUST tự tin cùng bạn triển khai chiến dịch quảng cáo Google thành công</h2>
                    <div style="margin-bottom: 20px;">Với hơn 10 năm kinh nghiệm trong lĩnh vực Google Ads chúng tôi tự
                        tin
                        mang lại thành công cho
                        bạn</div>
                </div>
            </div>
            <div class="row">
                <div *ngFor="let item of listChienDichQuangCao"
                    class="col-lg-3 mb-3 wrapper-google-ads-help-item-2-item">
                    <div class="wrapper-google-ads-help-item-2-item-content ">
                        <img src="{{item.urlImg}}" alt="">
                        <h4>{{item.title}}</h4>
                        <div>{{item.description}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid bg-grey wrap-google-sosanh">
        <div class="container">
            <div class="row text-center title-google-sosanh">
                <h2>Quảng cáo Google khác biệt gì so với quảng cáo truyền thống</h2>
            </div>
            <div class="row">
                <div class="col-lg-4 bg-white p-0">
                    <div class="header-table-sosanh text-center">
                        SO SÁNH
                    </div>
                    <div class="item-table-sosanh bg-grey-nhat">
                        VỊ TRÍ XUẤT HIỆN
                    </div>
                    <div class="item-table-sosanh">
                        THEO DÕI KẾT QUẢ
                    </div>
                    <div class="item-table-sosanh bg-grey-nhat">
                        SỐ LƯỢNG TỪ KHOÁ
                    </div>
                    <div class="item-table-sosanh">
                        THỜI GIAN
                    </div>
                </div>
                <div class="col-lg-4 bg-blue p-0">
                    <div class="header-table-sosanh text-center">
                        GOOGLE ADS
                    </div>
                    <div class="item-table-sosanh bg-blue-nhat">
                        Xuất hiện ở 4 vị trí đầu tiên trên quảng cáo Google
                    </div>
                    <div class="item-table-sosanh">
                        Dễ dàng tính toán hơn chi phí và doanh thu với báo cáo từ Google mỗi ngày
                    </div>
                    <div class="item-table-sosanh bg-blue-nhat">
                        Có thể nhắm nhiều từ khóa cùng một lúc với nhiều chiến dịch có mục tiêu khác nhau
                    </div>
                    <div class="item-table-sosanh ">
                        Thời gian để xuất hiện trên top rất nhanh (chỉ sau 5 phút xét duyệt) khi đội kỹ thuật chạy quảng
                        cáo
                        có kinh nghiệm
                    </div>
                </div>
                <div class="col-lg-4 bg-white p-0">
                    <div class="header-table-sosanh text-center">
                        CÁC HÌNH THỨC SEO KHÁC
                    </div>
                    <div class="item-table-sosanh bg-grey-nhat">
                        Xuất hiện dưới 4 vị trí quảng cáo
                    </div>
                    <div class="item-table-sosanh">
                        Khó khăn hơn vì còn nhiều yếu tố trong quá trình làm chiến dịch SEO bị thay đổi ảnh hưởng tới
                        chi
                        phí và doanh thu
                    </div>
                    <div class="item-table-sosanh bg-grey-nhat">
                        Cần tập trung trước vào một vài từ khoá, sau đó mới mở rộng dần thì mới hiệu quả
                    </div>
                    <div class="item-table-sosanh">
                        Mất nhiều thời gian 6 tháng đến 1 năm để xây dựng hệ thống SEO vững chắc.
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid  wrap-google-sosanh">
        <div class="container">
            <div class="row text-center title-google-tien-hanh">
                <h2>Các bước tiến hành quảng cáo Google với NEWTRUST Media</h2>
            </div>
            <div class="row text-center title-google-tien-hanh">
                <div>Với kinh nghiệm và sự tư duy sáng tạo trong quảng cáo, truyền thông BIN mang đến lợi ích tối ưu từ
                    phân
                    tích, đề xuất đến cung cấp các giải pháp marketing online phù hợp nhất cho từng lĩnh vực của mỗi
                    khách
                    hàng.</div>
            </div>
            <div class="row">
                <div *ngFor="let item of listCacBuocQuangCao" class="col-lg-3 wrapper-google-ads-help-item-2-item">
                    <div class="wrapper-google-ads-help-item-2-item-content pl-0 pr-0"
                        [ngClass]="{'bg-grey':item.id % 2}">
                        <img src="{{item.urlImg}}" alt="">
                        <h2 style=" color: #fc2b4ead;">{{item.buoc}}</h2>
                        <h4>{{item.title}}</h4>
                        <div>{{item.description}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid  wrap-google-sosanh bg-grey">
        <div class="container">
            <div class="row text-center title-google-tien-hanh">
                <h2>Bảng Báo Giá Dịch Vụ Quảng Cáo Google Ads</h2>
            </div>
            <div class="row">
                <div class="col-lg-8">
                    <h2>Đặt ngân sách phù hợp với bạn</h2>
                    <div class="font16">Với Google Ads, bạn có quyền kiểm soát chi phí quảng cáo trực tuyến. Số tiền bạn
                        chi
                        tiêu sẽ không
                        bao giờ vượt qua mức ngân sách mà bạn đưa ra. Bên cạnh đó, bạn còn có thể ngừng quảng cáo bất cứ
                        khi
                        nào bạn thấy không hiệu quả.</div>
                    <a class="btn-lien-he-google" style="border: solid 0.1px #000;" routerLink="/lien-he">Liên hệ
                        ngay</a>

                </div>
                <div class="col-lg-4 d-flex justify-content-center">
                    <img src="../../../../../assets/ngan-sach.png" alt="">
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid  wrap-google-sosanh">
        <div class="container">
            <div class="row text-center title-google-tien-hanh">
                <h2>Bảng báo giá Google trọn gói</h2>
            </div>
            <div class="row">
                <div class="col-lg-4 wrap-item-bang-gia" *ngFor="let item of listBangGia">
                    <div class="item-bang-gia" [ngStyle]="{'background-color': item.bgColor}">
                        <strong class="buoc-bao-gia" [ngStyle]="{'color': item.color}">
                            {{item.number}}
                        </strong>
                        <div class="chi-tiet-bao-gia font16">
                            {{item.description}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row text-center title-google-tien-hanh pt-5">
                <h3>Để có mức giá chính xác và tốt nhất khách hàng vui lòng liên hệ:</h3>
            </div>
            <div class="row">
                <div class="col-12 d-flex justify-content-center">
                    <a class="btn-lien-he-google hotline" href="tel:+84968630869">Hotline: 0968.630.869 </a>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid  wrap-google-sosanh">
        <div class="container">
            <div class="row text-center title-google-tien-hanh">
                <h2>Sơ bộ về cách tính phí</h2>
            </div>
            <div class="row">
                <div class="col-lg-4 wrap-item-bang-gia" *ngFor="let item of listSoBo">
                    <div class="item-bang-gia">
                        <div class="wrapper-google-ads-help-item-2-item-content pl-0 pr-0">
                            <img src="{{item.urlImg}}" alt="">
                            <h4>{{item.title}}</h4>
                            <div>{{item.description}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="container-fluid wrapper-quang-cao-google bg-google-ads-color">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <h2 class="line-before text-md-32 mb-20 text-white">NEWTRUST tự hào là đối tác cao cấp của Google
                    </h2>
                    <div class="text-white text-justify text-quang-cao-google">
                        <p>Chúng tôi tự hào là đối tác cao cấp của Google với nhiều năm kinh nghiệm. Những SỰ SÁNG TẠO,
                            TƯ
                            DUY VÀ KINH NGHIỆM mà chúng tôi có được sẽ giúp quảng cáo của bạn được xét duyệt nhanh chóng
                            trong TOP tìm kiếm.</p>
                        <a class="btn-lien-he-google mb-3" routerLink="/lien-he">Liên hệ ngay</a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <img class="w-100" src="../../../../../assets/img/google-partner-1599638534.jpg" width="100%" height="320px" alt=""
                        srcset="">
                </div>
            </div>
        </div>
    </div>


</div>

<div *ngIf="idActionTab == 2" @expandCollapse>
    <div class="container-fluid wrapper-quang-cao-google bg-google-ads-color">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <h2 class="line-before text-md-32 mb-20 text-white">Google Display Network là gì?</h2>
                    <div class="text-white text-justify text-quang-cao-google">
                        <p>GDN là viết tắt của Google Display Network một hệ thống mạng lưới gồm các trang web lớn là
                            đối tác của google cho phép ta sử dụng GDN có thể quảng cáo banner về sản phẩm, dịch vụ của
                            mình.</p>
                        <p>Google Display Network tiếp cận khách hàng bằng việc xuất hiện trên hơn 2 triệu trên Web và
                            hơn 650.000 ứng dụng trên các thiết bị. Hay có thể nói, quảng cáo của bạn trên mạng hiển thị
                            của Google sẽ tiếp cận 90% người dùng Internet trên toàn thế giới trên hàng triệu trang Web,
                            trang tin tức, blog và các trang Web Google như Gmail và YouTube.
                        </p>
                        <a class="btn-lien-he-google  mb-3" routerLink="/lien-he">Liên hệ ngay</a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <img src="../../../../../assets/img/what-google-display-network.jpg" width="550px" height="320px"
                        alt="" srcset="" class="w-100">
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid  wrap-google-sosanh bg-grey">
        <div class="container">
            <div class="row text-center title-google-tien-hanh">
                <h2 style="margin-bottom: 25px;">Ưu điểm của quảng cáo Google Display Network</h2>
                <div>Kết nối với khách hàng thông qua các ứng dụng và trang web yêu thích của họ</div>
            </div>
            <div class="row">
                <div class="col-lg-3 wrap-item-bang-gia" *ngFor="let item of listUuDienQuangCaoGoogle">
                    <div class="item-bang-gia">
                        <div class="wrapper-google-ads-help-item-2-item-content pl-0 pr-0">
                            <img src="{{item.urlImg}}" alt="">
                            <!-- <h4>{{item.title}}</h4> -->
                            <div>{{item.description}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container pt-5">
        <div class="row text-center title-google-tien-hanh">
            <h2 style="margin-bottom: 25px;">Các hình thức hiển thị của quảng cáo Google Display Network</h2>
            <div>Hiển thị trên các website của Google và đối tác. Hình thức quảng cáo rất đa dạng bao gồm:</div>
        </div>
        <div class="row">
            <div class="col-lg-4" *ngFor="let item of listCacHinhThucHienThiQuangCao">
                <div class="item-cach-thuc-hien-thi-qiang-cao">
                    <div class="pl-0 pr-0 text-center">
                        <img src="{{item.urlImg}}" alt="" class="w-100 mb-3">
                        <h4 class="mb-2">{{item.title}}</h4>
                        <div class="mb-5">{{item.description}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid  wrap-google-sosanh bg-grey">
        <div class="container">
            <h2 class="text-center">GOOGLE DISPLAY NETWORK (GDN) - Quảng Cáo Hiển Thị Hình ảnh</h2>
            <p>Google Display Network (GDN) là một dạng quảng cáo hiển thị của Google. Mẫu quảng cáo GDN tồn tại ở dưới
                dạng
                hỉnh ảnh (tĩnh hoặc động) và được đặt trên các trang web của các đơn vị liên kết chạy Google Adsense.
            </p>
            <p class="mb-30">Tại Việt Nam, các đơn vị liên kết chạy Google Adsense phần lớn đều là các báo lớn như Zing,
                VnExpress, Dân Trí, Tuổi Trẻ, Youtube, 24h… Cho nên quảng cáo Google Display Network ở Việt Nam phần lớn
                được dùng để quảng bá, tăng độ phủ thương hiệu.</p>
            <p>Hình thức này tính theo Click (CPC), khi quảng cáo được set up không tính số lần hiển thị, chỉ tính khi
                khách
                hàng click vào banner để dẫn về website của bạn mới tính là 1 click.</p>
            <p>Khách hàng có thể chọn lựa vị trí, chủ đề và website hiện thị quảng cáo. Thời gian quảng cáo có thể linh
                động
                theo tuần hoặc theo tháng.</p>
            <div class="line-vertical-before mb-30">
                <p><strong>BIN Media Hỗ trợ:</strong></p>
                <p>+ Thiết kế bộ banner miễn phí</p>
                <p>+ Tạo chiến dịch Remarketing tiếp thị lại những khách hàng đã truy cập vào website</p>
                <p class="mb-30">+ Báo cáo đầy đủ dữ liệu</p>
                <p>"Quảng cáo hiển thị hình ảnh sẽ giúp bạn nhắm đúng khách hàng mục tiêu. Tuy nhiên, để tiết kiệm thời
                    gian
                    và chi phí quảng cáo, bạn cần chọn chính xác “độ tuổi”, “giới tính”, nhắm đúng “Sở thích”, “Chủ đề”
                    các
                    nội dung trên các Website hiển thị và nhắm đúng nội dung các bài báo khách hàng sẽ đọc. Vì vậy ngay
                    từ
                    đầu hay bạn cần trao đổi chuyên gia quảng cáo. Với hơn 10 kinh nghiệm chạy quảng cáo và là đối tác
                    cấp
                    cao của Google tại Việt Nam, BIN Media sẽ là giải pháp quảng cáo trên Google hoàn hảo và tiết kiệm
                    cho
                    bạn."</p>
            </div>
            <div class="text-center">
                <h3 class="mb-20">Để có mức giá chính xác và tốt nhất khách hàng vui lòng liên hệ:</h3>
                <div class="row">
                    <div class="col-12 d-flex justify-content-center">
                        <a class="btn-lien-he-google hotline" href="tel:+84968630869">Hotline: 0968.630.869 </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="idActionTab == 3" @expandCollapse>
    <div class="container-fluid  wrap-google-sosanh bg-grey">
        <div class="container">
            <div class="row text-center title-google-tien-hanh">
                <h2 style="margin-bottom: 25px;">Tối đa hóa doanh thu với Google Search và Remarketing</h2>
                <div>Kết nối với khách hàng thông qua các ứng dụng và trang web yêu thích của họ</div>
            </div>
            <div class="row">
                <div class="col-lg-2"></div>
                <div class="col-lg-4 text-center">
                    <h3>Doanh nghiệp <span class="text-main">chưa áp dụng</span> <br> Search Ads Remarketing</h3>
                    <img src="../../../../../assets/img/remarketing-1.png" alt="" srcset="">
                    <p class="text-main mt-3">Khách hàng thoát trang, bỏ đi và không có ấn tượng về thương
                        hiệu, không tăng thêm doanh thu khi đã gần như có được khách hàng tìm năng.</p>
                </div>
                <div class="col-lg-4 text-center">
                    <h3 class="text-center">Doanh nghiệp <span class="text-ocean">đã áp dụng</span> <br> Search Ads
                        Remarketing</h3>
                    <img src="../../../../../assets/img/remarketing-2.png" alt="" srcset="">
                    <p class="text-ocean mt-3">Khách hàng ấn tượng, quay lại nhiều, <br> tăng doanh thu</p>
                </div>
                <div class="col-lg-2"></div>

            </div>
        </div>
    </div>

    <div class="container pt-5">
        <div class="row text-center title-google-tien-hanh">
            <h2 style="margin-bottom: 25px;">Các hình thức hiển thị của quảng cáo Google Display Network</h2>
        </div>
        <div class="row">
            <div class="col-lg-4" *ngFor="let item of listCacHinhThucRemaketing">
                <div class="item-cach-thuc-hien-thi-qiang-cao">
                    <div class="pl-0 pr-0 text-center">
                        <img src="{{item.urlImg}}" alt="" class="w-100 mb-3">
                        <h4 class="mb-2">{{item.title}}</h4>
                        <div class="mb-5">{{item.description}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid  bg-grey pt-5 pb-5">
        <div class="container">
            <div class="row text-center title-google-tien-hanh">
                <h2 style="margin-bottom: 25px;">Lợi ích khi quảng cáo Remarketing</h2>
            </div>
            <div class="row">
                <div class="col-lg-3 wrap-item-bang-gia" *ngFor="let item of listUuDienQuangCaoRemaketing">
                    <div class="">
                        <div class=" text-center pl-0 pr-0 bg-grey">
                            <img src="{{item.urlImg}}" alt="" class="mb-5">
                            <h4>{{item.title}}</h4>
                            <div class="text-justify">{{item.description}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid pt-5 pb-5">
        <div class="container">
            <div class="row text-center title-google-tien-hanh">
                <h2 style="margin-bottom: 25px;">Lợi ích khi quảng cáo Remarketing</h2>
            </div>
            <div class="row">
                <div class="col-lg-3 wrap-item-bang-gia">
                    <div class=" text-center pl-0 pr-0 ">
                        <img src="../../../../../assets/img/resolve-remarketing-1.png" alt="" class="mb-5">
                        <h3><span class="text-main">Giảm 20%</span> chi phí dành riêng cho quảng cáo</h3>
                    </div>
                </div>
                <div class="col-lg-3 wrap-item-bang-gia">
                    <div class=" text-center pl-0 pr-0 ">
                        <img src="../../../../../assets/img/resolve-remarketing-2.png" alt="" class="mb-5">
                        <h3><span class="text-main">Giảm 15%</span> chi phí CPA <br> (Chi phí mỗi hành động)</h3>
                    </div>
                </div>
                <div class="col-lg-3 wrap-item-bang-gia">
                    <div class=" text-center pl-0 pr-0 ">
                        <img src="../../../../../assets/img/resolve-remarketing-3.png" alt="" class="mb-5">
                        <h3><span class="text-ocean">Tăng 22%</span> chỉ số CR <br> (Tỉ lệ chuyển đổi hành vi)</h3>
                    </div>
                </div>
                <div class="col-lg-3 wrap-item-bang-gia">
                    <div class=" text-center pl-0 pr-0 ">
                        <img src="../../../../../assets/img/resolve-remarketing-4.png" alt="" class="mb-5">
                        <h3><span class="text-ocean">Tăng 17%</span> chỉ số LTV <br> (Giá trị tương lai mà người dùng
                            mang lại)</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="idActionTab == 4" @expandCollapse>
    <div class="container-fluid wrapper-quang-cao-google bg-google-ads-color">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <h2 class="line-before text-md-32 mb-20 text-white">App Campaigns là gì?</h2>
                    <div class="text-white text-justify text-quang-cao-google">
                        <p>Các dịch vụ “ứng dụng” trên IOS hoặc Android sẽ được quảng cáo bằng cách hiển thị trên tất cả
                            mạng hiển thị Google (Google Tìm Kiếm, YouTube, Google Play và các công cụ khác). Với chiến
                            dịch ứng dụng này sẽ giúp bạn tiếp cận đến những đối tượng quan tâm đến ứng dụng có liên
                            quan hoặc nó là ứng dụng của bạn.</p>
                        <p>Từ quảng cáo ứng dụng của bạn thì người dùng có thể nhấn trực tiếp để cài đặt. Google Ads sẽ
                            dựa vào vị trí quảng cáo của bạn xuất hiện để tùy chỉnh phân phát quảng cáo của bạn được tối
                            ưu vào đúng nơi và đúng thời điểm.
                        </p>
                        <a class="btn-lien-he-google  mb-3" routerLink="/lien-he">Liên hệ ngay</a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <img src="../../../../../assets/img/app-campaigns.jpg" width="550px" height="320px" alt="" srcset=""
                        class="w-100">
                </div>

            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-12 mt-5">
                <h2 class="mb-5 text-center">Các hoạt động của App Campaigns (quảng cáo ứng dụng)</h2>
            </div>
        </div>
        <div class="row bg-lg-light1">
            <div class="col-lg-6">
                <img src="https://d32q3bqti6sa3p.cloudfront.net/uploads/google-images/app-campaigns-activities-1.png"
                    alt="">
            </div>

            <div class="col-lg-6 d-flex align-items-center justify-content-center">
                <div class="text-justify mb-3 mt-3">Hiển thị trên “Mạng tìm kiếm” và “Mạng hiển thị” sẽ liên kết với cửa
                    hàng ứng dụng. Nội dung quảng
                    cáo có thể gồm: Biểu tượng ứng dụng, văn bản mô tả và xếp hạng trên cửa hàng ứng dụng.</div>
            </div>
        </div>

        <div class="row bg-lg-gray mt-5">

            <div class="col-lg-6 d-flex align-items-center justify-content-center">
                <div class="text-justify mb-3 mt-3">Hiển thị trên Google Play sẽ liên kết tới danh sách cửa hàng Play.
                    Nội dung quảng cáo có thể gồm: Biểu tượng ứng dụng, tên ứng dụng, tên nhà phát triển, xếp hạng trung
                    bình và văn bản tùy chỉnh của bạn.</div>
            </div>

            <div class="col-lg-6">
                <img src="https://d32q3bqti6sa3p.cloudfront.net/uploads/google-images/app-campaigns-activities-2.png"
                    alt="">
            </div>
        </div>

        <div class="row bg-lg-light1 mt-5">
            <div class="col-lg-6">
                <img src="https://d32q3bqti6sa3p.cloudfront.net/uploads/google-images/app-campaigns-activities-3.png"
                    alt="">
            </div>

            <div class="col-lg-6 d-flex align-items-center">
                <div class="text-justify mb-3 mt-3">
                    <div>Hiển thị Video và trang Web của nhà xuất bản trên Mạng hiển thị nhằm
                        quảng cáo lượt cài đặt ứng dụng và thu hút người dùng cài đặt ứng dụng của bạn, trực tiếp từ ứng
                        dụng YouTube hoặc bằng cách liên kết đến cửa hàng ứng dụng. Nội dung quảng cáo có thể gồm: Video
                        từ
                        liên kết trên YouTube, văn bản, biểu tượng ứng dụng, văn bản cửa hàng ứng dụng và xếp hạng trên
                        cửa
                        hàng ứng dụng.</div>

                    <div>Hiển thị trên YouTube (thiết bị iOS và Android) sẽ liên kết với cửa hàng ứng dụng. Nội dung
                        quảng cáo có thể gồm: Video từ liên kết trên YouTube, văn bản, biểu tượng ứng dụng, văn bản cửa
                        hàng ứng dụng và thông tin xếp hạng trên cửa hàng ứng dụng.</div>
                </div>
            </div>
        </div>


    </div>

    <div class="container pt-5 pb-5">
        <div class="row text-center title-google-tien-hanh">
            <h2 style="margin-bottom: 25px;">Thu hút người dùng cài ứng dụng đơn giản và hiệu quả hơn</h2>
            <div>Chỉ một chiến dịch duy nhất, quảng cáo ứng dụng của bạn sẽ hiển thị trên khắp Google và mang lại nhiều
                giá trị hơn cho bạn. Bạn có thể tối đa hóa chiến dịch quảng cáo ứng dụng nhờ các lý do sau:</div>
        </div>
        <div class="row">
            <div class="col-lg-6 d-flex align-items-center">
                <div class="row align-items-center">
                    <div class="col-12 d-flex align-items-center w-100" *ngFor="let item of lisThuHutNGuoiDung">
                        <div class="text-justify p-3 w-100 {{item.padding}}"
                            [style]="{'background-color': item.bgColor, 'border-radius': '5px'}">{{item.text}}</div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 d-flex align-items-center justify-content-center">
                <img src="../../../../../assets/img/attract-user.jpg" alt="">
            </div>
        </div>
    </div>

    <div class="container-fluid bg-grey pb-5">
        <div class="container">
            <div class="row text-center title-google-tien-hanh">
                <h2 style="margin-bottom: 25px; padding-top: 25px;">Ý tưởng văn bản</h2>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="title-luu-y-noi-dung-quang-cao d-flex justify-content-between"
                        (click)="ShowHideYTuong(1)">
                        <div> Ý tưởng văn bản</div>
                        <div>▼</div>
                    </div>
                    <div *ngIf="Ytuong1" class="noi-dung-title-luu-y-noi-dung-quang-cao" @expandCollapse2>
                        <div>
                            Nhập 4 dòng văn bản độc lập, các dòng này có thể được xoay vòng trong các quảng cáo của bạn
                            theo
                            thứ tự bất kỳ. Mỗi ý tưởng cần có độ dài từ 25 ký tự trở xuống. Bao gồm một ý tưởng có độ
                            dài từ
                            20 ký tự trở xuống để phù hợp với những không gian nhỏ hơn.
                        </div>
                    </div>
                    <div class="title-luu-y-noi-dung-quang-cao d-flex justify-content-between"
                        (click)="ShowHideYTuong(2)">
                        <div> Có tối đa 20 nội dung Video, hình ảnh, HTML5 để hỗ trợ quảng cáo Đối với Video</div>
                        <div>▼</div>
                    </div>
                    <div *ngIf="Ytuong2" class="noi-dung-title-luu-y-noi-dung-quang-cao" @expandCollapse2>
                        <div class="panel-collapse-spacing mb-20">
                            <p class="mb-20">Nhập 4 dòng văn bản độc lập, các dòng này có thể được xoay vòng trong các
                                quảng cáo của bạn theo thứ tự bất kỳ. Mỗi ý tưởng cần có độ dài từ 25 ký tự trở
                                xuống.Bao gồm một ý tưởng có độ dài từ 20 ký tự trở xuống để phù hợp với những không
                                gian nhỏ hơn.</p>
                            <p><strong>Đối với hình ảnh: Tải lên hình ảnh dưới dạng .jpg, .gif hoặc .png có kích thước
                                    tối đa là 150KB.</strong></p>
                            <p>- Quảng cáo gốc nên chọn hình ảnh ngang.</p>
                            <p>- Quảng cáo xen kẽ nên chọn hình ảnh dọc.</p>
                            <p class="mb-20">- Ngoài ra, ta có rất nhiều định dạng tham khảo khác như: 320x50 px,
                                320x480 px và 300x250 px.</p>
                            <p><strong>Đối với HTML5: Tải lên HTML5 dưới dạng .zip với kích thước tối đa 1MB và không
                                    quá 40 tệp. Các kích thước HTML5 hiện được chấp nhận:</strong></p>
                            <p>- 300x250 (kích thước cố định).</p>
                            <p>- 320x50 (kích thước cố định).</p>
                            <p>- 480x320 (kích thước biến thể, chuyển tiếp nằm ngang).</p>
                            <p class="mb-20">- 320x480 (kích thước biến thể, chuyển tiếp nằm dọc).</p>
                            <p>Google Ads không dịch ngôn ngữ cho quảng cáo của bạn, vì thế hãy chọn ngôn ngữ phù hợp
                                với quảng cáo của mình. Đặc biệt, tùy thuộc vào vị trí xuất hiện quảng cáo mà nội dung
                                quảng cáo ứng dụng có thể sẽ tự động cắt, sửa cho phù hợp mục đích hiệu suất và trải
                                nghiệm người dùng.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="idActionTab == 5" @expandCollapse>
    <div class="container-fluid wrapper-quang-cao-google bg-google-ads-color">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <h2 class="line-before text-md-32 mb-20 text-white">Google Shopping là gì?</h2>
                    <div class="text-white text-justify text-quang-cao-google">
                        <p>Google Shopping là hình thức quảng cáo đưa sản phẩm hiển thị dưới dạng hình ảnh, giá, tình
                            trạng tồn kho,.. bên dưới thanh tìm kiếm khi các khách hàng tìm kiếm các sản phẩm liên quan.
                            Thích hợp cho doanh nghiệp vừa và nhỏ.</p>
                        <p>Chỉ cần đăng ký Google Merchant Center và tạo chiến dịch trên Google Ads thì quảng cáo sẽ
                            hiển thị đến khách hàng trên Google và trên Web. Và bạn chỉ phải trả tiền khi khách hàng
                            nhấp qua trang web hoặc xem hàng tồn kho địa phương bạn.
                        </p>
                        <p>
                            Google Shopping có tỉ lệ chuyển đổi đơn hàng cao nhất tính đến nay.
                        </p>
                        <a class="btn-lien-he-google  mb-3" routerLink="/lien-he">Liên hệ ngay</a>
                    </div>
                </div>
                <div class="col-lg-6">
                    <img src="../../../../../assets/img/google-shopping.jpg" width="550px" height="320px" alt=""
                        srcset="" class="w-100">
                </div>

            </div>
        </div>
    </div>

    <div class="container-fluid  bg-grey pt-5 pb-5">
        <div class="container">
            <div class="row text-center title-google-tien-hanh">
                <h2 style="margin-bottom: 25px;">Google Shopping giúp doanh nghiệp bạn như thế nào?</h2>
            </div>
            <div class="row">
                <div class="col-lg-3 wrap-item-bang-gia" *ngFor="let item of listGoogleShopingThayDoiDoangNghiep">
                    <div class="">
                        <div class=" text-center pl-0 pr-0 bg-grey">
                            <img src="{{item.urlImg}}" alt="" class="mb-5">
                            <h4>{{item.title}}</h4>
                            <div class="text-center">{{item.description}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid  bg-hieu-qua-google-shopping pt-5 pb-5 ">
        <div class="container">
            <div class="row text-center title-google-tien-hanh">
                <h2 style="margin-bottom: 25px;">Những con số biết nói chứng minh hiệu quả của Google Shopping</h2>
            </div>
            <div class="row">
                <div class="col-lg-4 wrap-item-bang-gia"
                    *ngFor="let item of listGoogleShopingThayDoiDoangNghiepHieuQua">
                    <div class="">
                        <div class=" text-center pl-0 pr-0  color-white">
                            <h2>{{item.percent}}</h2>
                            <div class="text-center">{{item.description}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container mb-5">
        <div class="row text-center title-google-tien-hanh mt-5 mb-5">
            <h2>Bắt đầu thực hiện Google Shopping chỉ với 30 giây</h2>
        </div>
        <div class="row">
            <div *ngFor="let item of listCacBuocGoogleShopping" class="col-lg-3 wrapper-google-ads-help-item-2-item">
                <div class="wrapper-google-ads-help-item-2-item-content pl-0 pr-0" [ngClass]="{'bg-grey':item.id % 2}">
                    <img src="{{item.urlImg}}" alt="">
                    <h2 style=" color: #fc2b4ead;">{{item.buoc}}</h2>
                    <h4>{{item.title}}</h4>
                </div>
            </div>
        </div>
        <div class="row ">
            <div class="col-12 d-flex align-items-center justify-content-center mt-5">
                <a class="btn-lien-he-google  mb-3" style="background-color: #e2104f !important; color: #fff;"
                    routerLink="/lien-he">Liên hệ ngay</a>
            </div>
        </div>
    </div>
    <div class="container mb-5">
        <div class="row align-items-center">
            <div class="col-lg-4 text-center mb-5 mb-lg-0"><img class=" lazyloaded"
                    src="https://d32q3bqti6sa3p.cloudfront.net/uploads/google-images/google-shopping.png"
                    data-src="https://d32q3bqti6sa3p.cloudfront.net/uploads/google-images/google-shopping.png"
                    alt="Google Shopping" title="Google Shopping"></div>
            <div class="col-lg-8"><img class=" lazyloaded"
                    src="https://d32q3bqti6sa3p.cloudfront.net/uploads/google-images/google-shopping-logo.png"
                    data-src="https://d32q3bqti6sa3p.cloudfront.net/uploads/google-images/google-shopping-logo.png"
                    alt="Google Shopping" title="Google Shopping">
                <p class="mt-4"><strong>Quảng cáo mua sắm dường như là hoàn thiện, tân tiến và hiệu quả nhất bởi sự tiện
                        lợi, mạng lưới rộng và thỏa mãn người bán lẫn người mua.</strong></p>
            </div>
        </div>
    </div>

</div>

<div class="container-fluid wraper-doi-ngu-tu-van">
    <div class="container">
        <div class="row">
            <div class="col-12 wrap-content-doi-ngu-tu-van">
                <h3>Đội ngũ tư vấn của NEWTRUST</h3>
                <div>Luôn sẵn sàng hỗ trợ bạn mọi lúc mọi nơi</div>
                <a class="btn-lien-he-google" href="tel:+84968630869">Hotline: 0968.630.869</a>
            </div>
        </div>
    </div>
</div>