import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-thiet-ke-ung-dung-di-dong',
  templateUrl: './thiet-ke-ung-dung-di-dong.component.html',
  styleUrls: ['./thiet-ke-ung-dung-di-dong.component.scss']
})
export class ThietKeUngDungDiDongComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
