import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-facebook-ads',
  templateUrl: './facebook-ads.component.html',
  styleUrls: ['./facebook-ads.component.scss']
})
export class FacebookAdsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
