import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './components/layouts/preloader/preloader.component';
import { HeaderComponent } from './components/layouts/header/header.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { AboutComponent } from './components/pages/about/about.component';
import { ServiceComponent } from './components/pages/service/service.component';
import { ServiceTwoComponent } from './components/pages/service-two/service-two.component';
import { ServiceThreeComponent } from './components/pages/service-three/service-three.component';
import { ServiceDetailsComponent } from './components/pages/service-details/service-details.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogRightSidebarComponent } from './components/pages/blog-right-sidebar/blog-right-sidebar.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { RecentProjectComponent } from './components/pages/recent-project/recent-project.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { TeamComponent } from './components/pages/team/team.component';
import { TeamTwoComponent } from './components/pages/team-two/team-two.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { FormsModule } from '@angular/forms';
import { LoadingDirective } from './directive/Loading.directive';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { FacebookAdsComponent } from './components/pages/service-page/facebook-ads/facebook-ads.component';
import { GoogleAdsComponent } from './components/pages/service-page/google-ads/google-ads.component';
import { TiktokAdsComponent } from './components/pages/service-page/tiktok-ads/tiktok-ads.component';
import { XayDungKenhTiktokComponent } from './components/pages/service-page/xay-dung-kenh-tiktok/xay-dung-kenh-tiktok.component';
import { XayDungFanpageComponent } from './components/pages/service-page/xay-dung-fanpage/xay-dung-fanpage.component';
import { LiveStreamChuyenNghiepComponent } from './components/pages/service-page/live-stream-chuyen-nghiep/live-stream-chuyen-nghiep.component';
import { ThietKeWebsiteComponent } from './components/pages/service-page/thiet-ke-website/thiet-ke-website.component';
import { ThietKeUngDungDiDongComponent } from './components/pages/service-page/thiet-ke-ung-dung-di-dong/thiet-ke-ung-dung-di-dong.component';
import { ThietKeLogoComponent } from './components/pages/service-page/thiet-ke-logo/thiet-ke-logo.component';
import { ThietKeBoNhanDienThuongHieuComponent } from './components/pages/service-page/thiet-ke-bo-nhan-dien-thuong-hieu/thiet-ke-bo-nhan-dien-thuong-hieu.component';
import { ThietKePosterToRoiComponent } from './components/pages/service-page/thiet-ke-poster-to-roi/thiet-ke-poster-to-roi.component';
import { ThietKeHoSoNangLucCatalogComponent } from './components/pages/service-page/thiet-ke-ho-so-nang-luc-catalog/thiet-ke-ho-so-nang-luc-catalog.component';
import { ThietKeBienQuangCaoComponent } from './components/pages/service-page/thiet-ke-bien-quang-cao/thiet-ke-bien-quang-cao.component';
import { CarouselComponent } from './Share/carousel/carousel.component';

@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    HeaderComponent,
    FooterComponent,
    HomeOneComponent,
    HomeTwoComponent,
    HomeThreeComponent,
    AboutComponent,
    ServiceComponent,
    ServiceTwoComponent,
    ServiceThreeComponent,
    ServiceDetailsComponent,
    BlogComponent,
    BlogRightSidebarComponent,
    BlogDetailsComponent,
    PricingComponent,
    RecentProjectComponent,
    ErrorComponent,
    TeamComponent,
    TeamTwoComponent,
    ContactComponent,
    LoadingDirective,
    FacebookAdsComponent,
    GoogleAdsComponent,
    TiktokAdsComponent,
    XayDungKenhTiktokComponent,
    XayDungFanpageComponent,
    LiveStreamChuyenNghiepComponent,
    ThietKeWebsiteComponent,
    ThietKeUngDungDiDongComponent,
    ThietKeLogoComponent,
    ThietKeBoNhanDienThuongHieuComponent,
    ThietKePosterToRoiComponent,
    ThietKeHoSoNangLucCatalogComponent,
    ThietKeBienQuangCaoComponent,
    CarouselComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({ positionClass: 'toast-bottom-right', })
  ],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
  bootstrap: [AppComponent]
})
export class AppModule { }
