import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-xay-dung-kenh-tiktok',
  templateUrl: './xay-dung-kenh-tiktok.component.html',
  styleUrls: ['./xay-dung-kenh-tiktok.component.scss']
})
export class XayDungKenhTiktokComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
